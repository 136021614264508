import React, { Component } from 'react';
//import Coverflow from 'react-coverflow';
import CustomImg from '../CustomImg';
import { flutter, flu } from '../../utils/data';
import { OutboundLink } from 'gatsby-plugin-google-analytics';
import ScrollAnimation from 'react-animate-on-scroll';
import { auto } from 'eol';
//const windowGlobal = typeof window !== 'undefined' && window;
import { StyleRoot } from 'radium';

class Flutter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            flutter: ''
        }
    }

    componentDidMount() {
        const windowGlobal = typeof window !== 'undefined' && window

        const Coverflow = require('react-coverflow');

        const a = <Coverflow
            clickable={true}
            infiniteScroll={true}
            displayQuantityOfSide={2}
            enableScroll={true}
            enableHeading={false}
            navigation={true}
            active={0}

            media={{

                '@media (max-width: 900px)': {
                    width: windowGlobal.width,

                    height: '300px'
                },
                '@media (min-width: 900px)': {
                    width: windowGlobal.width,
                    height: '480px'
                }
            }}
        >
            {flutter.map((project, i) => (
                <img
                    src={project.image}
                    className='img-fluid'
                    alt={project.title}
                    data-action={project.action}
                    height="230px" width="230px"
                />



            ))}
        </Coverflow>;

        this.setState({
            flutter: a
        })
    }

    render() {
        return (
            <div>
                <section className="bg-white pt-5" id="flutter" >
                    <div className="container-fluid ">
                        <div className="row">
                            <div className="col-lg-12 text-center">
                                <h2 className="section-heading text-uppercase wow fadeInDown">   <ScrollAnimation animateIn='fadeInDown' animateOnce={true}>Flutter </ScrollAnimation></h2>
                                <h3 className="section-subheading text-muted wow bounceIn">   <ScrollAnimation animateIn='bounceIn' animateOnce={true}>Some of flutter app design practise. </ScrollAnimation></h3>
                            </div>
                        </div>

                        <div className="row">

                            <div className="container">
                                {!!this.state.flutter && this.state.flutter}
                            </div>
                        </div>
                        <div className="row mt-5 bg-info p-5 ">


                            {flu.map((item) => <div className="col-sm-4 d-flex justify-content-center">
                                <OutboundLink href={item.url} className="project-link" target="_blank" rel="noopener noreferrer">
                                    <img src={item.link} height="500px" />
                                    <h6 className="text-white mt-3 text-center">{item.title}</h6>
                                </OutboundLink>
                            </div>)}
                        </div>

                    </div>

                </section>
            </div>
        );
    }
}
export default Flutter;