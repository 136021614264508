import React from 'react';
import Tools from './Tools';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import ScrollAnimation from 'react-animate-on-scroll';

const Skills = () => {
    return (
        <div>
            <section id="services ">
                <div className="container mt-5">
                    <div className="row">
                        <div className="col-lg-12 text-center">
                            <h2 className="section-heading text-uppercase wow fadeInDown"><ScrollAnimation
                                animateIn='fadeInDown' animateOnce={true}>Technical Skills</ScrollAnimation></h2>
                            <h3 className="section-subheading text-muted wow bounceIn"><ScrollAnimation
                                animateIn='bounceIn' animateOnce={true}>Software Developer</ScrollAnimation></h3>
                        </div>
                    </div>
                    <div className="row text-center tech-icons">
                        <div className="col-md-3 wow slideInLeft"><ScrollAnimation animateIn='slideInLeft'
                                                                                   animateOnce={true}>
                            <FontAwesomeIcon icon={['fas', 'layer-group']}/>
                            <h4 className="service-heading">Backend</h4>
                            <p className="text-muted wow fadeIn">Java, Spring, AWS, Docker, Hibernate, Message Brokers -
                                ActiveMQ, Security - JWT, Oauth2, Testing - Mockito.</p>
                        </ScrollAnimation>
                        </div>
                        <div className="col-md-3 wow fadeInUp"><ScrollAnimation animateIn='fadeInUp' animateOnce={true}>
                            <FontAwesomeIcon icon={['fas', 'desktop']}/>
                            <h4 className="service-heading">Frontend</h4>
                            <p className="text-muted  wow fadeIn">React, JavaScript, Bootstrap.</p></ScrollAnimation>
                        </div>
                        <div className="col-md-3 wow fadeInUp"><ScrollAnimation animateIn='fadeInUp' animateOnce={true}>
                            <FontAwesomeIcon icon={['fab', 'android']}/>
                            <h4 className="service-heading">App Development</h4>
                            <p className="text-muted  wow fadeIn">Java, Flutter</p></ScrollAnimation>
                        </div>
                        <div className="col-md-3 wow slideInRight"><ScrollAnimation animateIn='slideInRight'
                                                                                    animateOnce={true}>
                            <FontAwesomeIcon icon={['fas', 'cubes']}/>
                            <h4 className="service-heading">Tools &amp; Technology</h4>
                            <p className="text-muted  wow fadeIn">Intellij, Android Studio, STS, Visual Studio, Git,
                                MySQL, Heroku, Postman</p></ScrollAnimation>
                        </div>
                    </div>
                </div>
            </section>
            <Tools/>
        </div>
    );
};

export default Skills;