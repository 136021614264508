import React from 'react';
import {experiences} from '../../utils/data';
import CustomImg from '../CustomImg';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {OutboundLink} from 'gatsby-plugin-google-analytics';
import ScrollAnimation from 'react-animate-on-scroll';

const Experience = () => {

    return (
        <>
            <section className="mt-5" id="team">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 text-center">
                            <h2 className="section-heading text-uppercase wow slideInDown">
                                <ScrollAnimation animateIn='slideInDown' animateOnce={true}>Professional
                                    Experience </ScrollAnimation></h2>
                            <h3 className="section-subheading text-muted wow bounceIn">
                                <ScrollAnimation animateIn='bounceIn' animateOnce={true}>Future comes one day at a
                                    time. </ScrollAnimation></h3>
                        </div>
                    </div>
                    <div className="row">
                        {experiences.map((company, i) => (
                            // wow ${i % 2 === 0 ? 'slideInLeft' : 'slideInRight'} 
                            <div key={i} className={`col-sm-4 `}>
                                <ScrollAnimation animateIn={`${company.animation}`} animateOnce={true}>
                                    {/* <div className="team-member">
                                        <CustomImg image={company.companyLogo} />
                                        <OutboundLink href={company.companyWebsite} target="_blank" rel="noopener noreferrer">
                                            <h4>{company.companyName}</h4>
                                        </OutboundLink>
                                        <p className="text-muted">{company.position}</p>
                                        <ul className="list-inline social-buttons">
                                            {company.toolsImage.map((tool, i) => (
                                                <li key={i} className="list-inline-item">
                                                    <CustomImg image={tool} />
                                                </li>
                                            ))}
                                        </ul>

                                    </div> */}

                                    <div className="col-lg-4  col-md-6  team-card mb-5">
                                        <div className="card team-box">
                                            <div className="img_div">
                                                {/* <img src={company.companyLogo.companyLogo} class="card-img-top img-fluid" alt="Responsive image" /> */}
                                                <CustomImg image={company.companyLogo}/>
                                                <div className="t-description">
                                                    <div className="content-wrapper">
                                                        <h4 className="h-alt">Hello!</h4>
                                                        <p>{company.Overview}</p>
                                                        <p>{company.period}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card-body">
                                                <div className="t-info">
                                                    {/* <OutboundLink href={company.companyWebsite} target="_blank" rel="noopener noreferrer"> */}
                                                    <h4 className="t-name">{company.companyName}</h4>
                                                    {/* </OutboundLink> */}

                                                    <span className="t-role">{company.position}</span>
                                                    {/* <span className="t-role">{company.duration}</span> */}
                                                    <span className="t-role">{company.location}</span>
                                                    <ul className="social-links">

                                                        {company.socialMedia.map((social, i) => <li key={i}
                                                                                                    className="list-inline-item">
                                                                <OutboundLink href={social.url} target="_blank"
                                                                              rel="noopener noreferrer" className="pr-0">
                                                                    <FontAwesomeIcon
                                                                        icon={[`${social.icontitle}`, `${social.icon}`]}
                                                                        title={"HTML5"} color={social.color}/>
                                                                </OutboundLink>
                                                            </li>
                                                        )}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </ScrollAnimation>
                            </div>
                        ))}
                    </div>

                    <div className="row wow slideInUp">
                        <div className="col-lg-8 mx-auto text-center">
                            <p className="large text-muted"><ScrollAnimation animateIn="fadeIn" animateOnce={true}>If
                                you can dream it, you can become it. </ScrollAnimation></p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Experience;