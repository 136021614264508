import React from 'react';
import ContactCircle from './ContactCircle';
import profile from '../../images/mausam.png';
import slogan from '../../images/hello.png';
import ScrollAnimation from 'react-animate-on-scroll';

const Header = () => {
    return (
        <header className="masthead text-center text-white d-flex">
            <div className="container  align-self-end">
                <div className="row mb-5">

                    <div className="col-lg-10 mx-auto wow slideInDown">
                        <ScrollAnimation animateIn='slideInDown' animateOnce={true} >
                            <img src={profile} className="image-fluid  " alt="img" width="200px" />
                        </ScrollAnimation>
                    </div>

                    <div className="col-lg-10 mx-auto wow slideInDown mt-5">
                        <ScrollAnimation animateIn='slideInDown' animateOnce={true} >
                            <img src={slogan} className="image-fluid " alt="img" width="200px" />
                        </ScrollAnimation>
                    </div>

                    <div className="col-lg-8 mx-auto mt-3 mb-2">
                        <ScrollAnimation animateIn='bounceIn' animateOnce={true}>  <p className="text-faded pb-5 ">I am person who is looking forward to build a successful career in a challenging environment, where my
                            knowledge and experience can be effectively utilized and enriched.
                        </p></ScrollAnimation>
                        <ContactCircle />
                    </div>
                </div>
            </div>
        </header>
    );
};

export default Header;