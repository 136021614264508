import React from 'react';
import {certification} from '../../utils/data';
import CustomImg from '../CustomImg';
import ScrollAnimation from 'react-animate-on-scroll';

const Certification = () => {

    return (
        <>
            <section className="mt-5" id="team">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 text-center">
                            <h2 className="section-heading text-uppercase wow slideInDown">
                                <ScrollAnimation animateIn='slideInDown'
                                                 animateOnce={true}>Certification</ScrollAnimation></h2>
                            <h3 className="section-subheading text-muted wow bounceIn">
                                <ScrollAnimation animateIn='bounceIn' animateOnce={true}>More to
                                    come. </ScrollAnimation></h3>
                        </div>
                    </div>
                    <div className="row">
                        {certification.map((company, i) => (
                            <div key={i} className={`col-sm-6 `}>
                                <ScrollAnimation animateIn={`${company.animation}`} animateOnce={true}>
                                    <div className="col-lg-6  col-md-6  team-card mb-5">
                                        <div className="card team-box-certification">
                                            <div className="img_div">
                                                <CustomImg image={company.companyLogo}/>
                                            </div>
                                            <div className="card-body">
                                                <div className="t-info">
                                                    <h4 className="t-name">{company.companyName}</h4>
                                                    <span className="t-role">{company.certification}</span>
                                                    <span className="t-role">{company.certified}</span>

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </ScrollAnimation>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        </>
    );
};

export default Certification;