import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ScrollAnimation from 'react-animate-on-scroll';
const Tools = () => {
    return (
        <div className="container-fluid tools-div mt-5 mb-5 ">
            <ul className="list-inline dev-icons">
                <li className="list-inline-item wow bounceInLeft">
                    <ScrollAnimation animateIn='bounceInLeft' animateOnce={false}>
                        <FontAwesomeIcon icon={['fab', 'java']} title={"Java"} /> </ScrollAnimation>
                </li>
                <li className="list-inline-item wow bounceInLeft">
                    <ScrollAnimation animateIn='bounceInLeft' animateOnce={false}>
                        <FontAwesomeIcon icon={['fab', 'aws']} title={"Java"} /> </ScrollAnimation>
                </li>
                <li className="list-inline-item wow bounceInLeft"> <ScrollAnimation animateIn='bounceInLeft' animateOnce={false}>
                    <FontAwesomeIcon icon={['fas', 'leaf']} title={"Spring"} /> </ScrollAnimation>
                </li>
                <li className="list-inline-item wow fadeInDown"> <ScrollAnimation animateIn='fadeInDown' animateOnce={false}>
                    <FontAwesomeIcon icon={['fab', 'android']} title={"Android"} /> </ScrollAnimation>
                </li>
                <li className="list-inline-item wow fadeInUp"> <ScrollAnimation animateIn='fadeInUp' animateOnce={false}>
                    <FontAwesomeIcon icon={['fab', 'js-square']} title={"JavaScript"} /> </ScrollAnimation>
                </li>
                <li className="list-inline-item wow fadeInDown"> <ScrollAnimation animateIn='fadeInDown' animateOnce={false}>
                    <FontAwesomeIcon icon={['fab', 'html5']} title={"HTML5"} /> </ScrollAnimation>
                </li>
                <li className="list-inline-item wow fadeInDown"> <ScrollAnimation animateIn='fadeInDown' animateOnce={false}>
                    <FontAwesomeIcon icon={['fab', 'jenkins']} title={"HTML5"} /> </ScrollAnimation>
                </li>
                <li className="list-inline-item wow fadeInUp"> <ScrollAnimation animateIn='fadeInUp' animateOnce={false}>
                    <FontAwesomeIcon icon={['fab', 'react']} title={"React"} /> </ScrollAnimation>
                </li>
                <li className="list-inline-item wow bounceInRight"> <ScrollAnimation animateIn='bounceInRight' animateOnce={false}>
                    <FontAwesomeIcon icon={['fab', 'css3-alt']} title={"CSS3"} /> </ScrollAnimation>
                </li>
                <li className="list-inline-item wow bounceInRight"> <ScrollAnimation animateIn='bounceInRight' animateOnce={false}>
                    <FontAwesomeIcon icon={['fab', 'docker']} title={"Github"} /> </ScrollAnimation>
                </li>
                <li className="list-inline-item wow bounceInRight"> <ScrollAnimation animateIn='bounceInRight' animateOnce={false}>
                    <FontAwesomeIcon icon={['fab', 'github']} title={"Github"} /> </ScrollAnimation>
                </li>


            </ul>
        </div>
    );
};

export default Tools;