import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { OutboundLink } from "gatsby-plugin-google-analytics";
import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { P_EMAIL, P_GITHUB, P_LINKEDIN, P_MEDIUM } from "../../utils/Constant";

const ContactCircle = () => {
  return (
    <div>
      <ul className="list-inline social-buttons ">
        <li className="list-inline-item wow slideInLeft">
          <ScrollAnimation animateIn="slideInLeft" animateOnce={false}>
            <OutboundLink
              href={P_LINKEDIN}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={["fab", "linkedin"]} title={"linkedin"} />
            </OutboundLink>
          </ScrollAnimation>
        </li>
        {/* REMOVING FACEBOOK LINK FROM CONTACT INFORMATION */}
        {/* <li className="list-inline-item wow slideInUp">
                    <ScrollAnimation animateIn='slideInUp' animateOnce={false}>
                        <OutboundLink href={P_FACEBOOK} target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon icon={['fab', 'facebook-square']} title={"facebook"} />
                        </OutboundLink>
                    </ScrollAnimation>

                </li> */}
        <li className="list-inline-item wow slideInDown">
          <ScrollAnimation animateIn="slideInDown" animateOnce={false}>
            <OutboundLink
              href={P_GITHUB}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={["fab", "github"]} title={"Github"} />
            </OutboundLink>
          </ScrollAnimation>
        </li>
        <li className="list-inline-item wow slideInUp">
          <ScrollAnimation animateIn="slideInUp" animateOnce={false}>
            <OutboundLink
              href={P_MEDIUM}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={["fab", "skype"]} title={"Skype"} />
            </OutboundLink>
          </ScrollAnimation>
        </li>

        <li className="list-inline-item wow slideInRight">
          <ScrollAnimation animateIn="slideInRight" animateOnce={false}>
            <OutboundLink
              href={P_EMAIL}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Mailto
                email={P_EMAIL}
                subject="Hello & Welcome"
                body="Hello there, Enter your message!!"
              >
                <FontAwesomeIcon icon={["fa", "envelope"]} title={"Email"} />
              </Mailto>
            </OutboundLink>
          </ScrollAnimation>
        </li>
      </ul>
    </div>
  );
};

const Mailto = ({ email, subject, body, children }) => {
  return (
    <a
      href={`mailto:${email}?subject=${
        encodeURIComponent(subject) || ""
      }&body=${encodeURIComponent(body) || ""}`}
    >
      {children}
    </a>
  );
};

export default ContactCircle;
