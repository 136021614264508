import img_one from '../images/flutter/app.gif';
import img_two from '../images/flutter/cone.gif';
import img_three from '../images/flutter/one.jpg';
import img_four from '../images/flutter/oone.gif';
import nic from '../images/flutter/nic.gif';
import showtime from '../images/flutter/showtime.gif';
import nicTwo from '../images/flutter/nicTwo.gif';

export const flu = [
    {
        'link': nic,
        'url': 'https://github.com/mausamRayamajhi/flutter_nic_asia',
        'title': 'NIC ASIA CLONE (Login)'
    },
    {
        'link': nicTwo,
        'url': 'https://github.com/mausamRayamajhi/flutter_nic_asia',
        'title': 'NIC ASIA CLONE (Dashboard)'
    },
    {
        'link': showtime,
        'url': 'https://github.com/mausamRayamajhi/show_time',
        'title': 'Show time'
    }
]
export const flutter = [
    {
        "image": img_one,
        "title": "Animated bottom",
        "action": "https://github.com/mausamRayamajhi/flutter_animated_bottom_bar"
    },
    {
        "image": img_two,
        "title": "Flight",
        "action": "https://github.com/mausamRayamajhi/flutter_flight"
    },
    {
        "image": img_three,
        "title": "Cake",
        "action": "https://github.com/mausamRayamajhi/flutter_cake"
    },
    {
        "image": img_four,
        "title": "Online",
        "action": "https://github.com/mausamRayamajhi/flutter_online_demo"
    }
]

export const myPortfolio = [
    {
        "projectImage": {
            "alt": "Exam information management system",
            "imageName": "leave-management.png",
            "className": "img-portfolio",
            "desc": "Exam information management system built using java"
        },
        "caption": "Exam information management system (EIS)",
        "link": "https://github.com/KCC-Project/exam8th",
        "desc": "Java, MySQL",
        "animation": "slideInLeft"
    },
    {
        "projectImage": {
            "alt": "EIS screenshot",
            "imageName": "EIS.jpg",
            "className": "img-portfolio",
            "desc": "EIS android application build during in college project"
        },
        "caption": "EIS (Android App)",
        "link": "https://github.com/mausamRayamajhi/EIS",
        "desc": "Java",
        "animation": "bounceIn"
    },
    {
        "projectImage": {
            "alt": "Iaeste cms screenshot",
            "imageName": "iaeste-volunteer1.png",
            "className": "img-portfolio",
            "desc": "IAESTE website build during volunteer period, hosted on heroku"
        },
        "caption": "IAESTE CMS",
        "link": "https://miaeste.herokuapp.com/",
        "desc": "Spring, React, Redux, MySQL",
        "animation": "slideInRight"
    },
    {
        "projectImage": {
            "alt": "ecommerce screenshot",
            "imageName": "pasalpalika.JPG",
            "className": "img-portfolio",
            "desc": "E-commerce website build with Java, Spring Boot, ReactJs"
        },
        "caption": "E-commerce",
        "link": "https://bitbucket.org/mausamRayamajhi/modifiedecommerce",
        "desc": "Java,Spring, React, Redux, MySQL",
        "animation": "slideInLeft"
    },
    {
        "projectImage": {
            "alt": "Note screenshot",
            "imageName": "note.jpg",
            "className": "img-portfolio",
            "desc": "Note is Android application build with java to take simple note."
        },
        "caption": "Note (Android App)",
        "link": "https://github.com/mausamRayamajhi/Notes",
        "desc": "Java",
        "animation": "bounceIn"
    },
    {
        "projectImage": {
            "alt": "Chat application screenshot",
            "imageName": "chatApp.png",
            "className": "img-portfolio",
            "desc": "Chat is simple chat application build with JMS (ActiveMQ)."
        },
        "caption": "Chat Application (JMS)",
        "link": "https://github.com/mausamRayamajhi/Notes",
        "desc": "JMS, ActiveMQ, Spring",
        "animation": "slideInRight"
    },

];


export const experiences = [
    {
        "animation": "slideInLeft",
        "companyName": "Accenture",
        "companyLogo": {
            "alt": "Accenture logo",
            "imageName": "accenture.png",
            "className": "company-logo",
            "desc": "Accenture"
        },
        "companyWebsite": "https://www.accenture.com/au-en",
        "position": "Senior Software Engineer",
        "duration": "Nov 2021 - Present",
        "Overview": "Joined in 2021, still learning and working with the team of professional software engineers.",
        "period": "Got lucky enough to join one of A Fortune Global 500 company, one of the great company to look and work for.",
        "location": "Australia",
        "socialMedia": [
            {
                "url": "https://www.accenture.com/gb-en",
                "color": "#BD00FF",
                "icon": "internet-explorer",
                "icontitle": "fab"
            },
            {"url": "https://www.facebook.com/accenture", "color": "#1197F5", "icon": "facebook", "icontitle": "fab"},
            {
                "url": "https://www.linkedin.com/company/accentureaustralia/",
                "color": "#0B66C2",
                "icon": "linkedin",
                "icontitle": "fab"
            },
        ],
        "toolsImage": [
            {"alt": "", "imageName": "", "className": "company-logo", "desc": ""},
        ]
    },
    {
        "animation": "fadeInUp",
        // "companyName": "Splash Information Services Incorporated",
        "companyName": "Splash",
        "companyLogo": {
            "alt": "f1softSplash logo",
            "imageName": "splash2.png",
            "className": "company-logo",
            "desc": "splash"
        },
        "companyWebsite": "http://www.splashsoftwares.com/index.html",
        "position": "Junior Java Developer",
        "duration": "April 2018 - Jan 2019",
        "Overview": "Joined after my Bachelor program, got changed chance to be part of small start up company.",
        "period": "Splash helped me to explore different tools and technologies that are essential for my future career growth.",
        "location": "Nepal",
        "socialMedia": [
            {
                "url": "http://www.splashsoftwares.com/index.html",
                "color": "#BD00FF",
                "icon": "internet-explorer",
                "icontitle": "fab"
            },
            // { "url": "https://www.facebook.com/accenture", "color": "#1197F5", "icon": "facebook", "icontitle": "fab" },
            {
                "url": "https://www.linkedin.com/company/splashsoftwares/about/",
                "color": "#0B66C2",
                "icon": "linkedin",
                "icontitle": "fab"
            },
        ],
        "toolsImage": [
            {"alt": "", "imageName": "", "className": "company-logo", "desc": ""},
        ]
    },
    {
        "animation": "slideInRight",
        "companyName": "IAESTE NEPAL",
        "companyLogo": {"alt": "iaeste logo", "imageName": "iaeste.png", "className": "company-logo", "desc": "IAESTE"},
        "companyWebsite": "https://iaeste.org/",
        "duration": "Nov 2021 - Present",
        "Overview": "Joined as volunteer for developing website for IAESTE NEPAL - CIVIL INITIATIVE.",
        "position": "Volunteer",
        "period": "Got chance to learning the different components of building a web application with technologies like ReactJs.",
        "location": "Nepal",
        "socialMedia": [
            {"url": "https://iaeste.org/", "color": "#BD00FF", "icon": "internet-explorer", "icontitle": "fab"},
            // { "url": "http://civilinitiative.org/iaeste-nepal/", "color": "#BD00FF", "icon": "internet-explorer", "icontitle": "fab" },
            {
                "url": "https://www.facebook.com/civilinitiative.iaeste/",
                "color": "#1197F5",
                "icon": "facebook",
                "icontitle": "fab"
            },
            {
                "url": "https://www.linkedin.com/company/iaeste/",
                "color": "#0B66C2",
                "icon": "linkedin",
                "icontitle": "fab"
            },
        ],
        "toolsImage": [
            {"alt": "", "imageName": "", "className": "company-logo", "desc": ""},
        ]
    }

];

export const certification = [
    {
        "animation": "slideInLeft",
        "companyName": "AWS",
        "companyLogo": {"alt": "aws logo", "imageName": "aws.png", "className": "aws-logo", "desc": "AWS"},
        "companyWebsite": "https://www.credly.com/badges/6de13106-d7f6-4971-81b5-110a6695a0e4/linked_in_profile",
        "certification": "Cloud Practitioner",
        "certified": "Jun 2023",
    },
    {
        "animation": "slideInRight",
        "companyName": "Agile",
        "companyLogo": {"alt": "agile logo", "imageName": "agile.png", "className": "agile-logo", "desc": "agile"},
        "companyWebsite": "https://www.icagile.com/credentials/69d45151-c58d-439d-8bfc-c1bbfcc06234",
        "certification": "ICAgile Professional",
        "certified": "Mar 2022",
    },

];