import React from 'react';

import CustomImg from '../CustomImg';
import { myPortfolio } from '../../utils/data';
import { OutboundLink } from 'gatsby-plugin-google-analytics';
import ScrollAnimation from 'react-animate-on-scroll';

const Projects = () => {
    return (
        <div>
            <section className="bg-light" id="projects">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 text-center">
                            <h2 className="section-heading text-uppercase wow fadeInDown">   <ScrollAnimation animateIn='fadeInDown' animateOnce={true}>Projects Showcase </ScrollAnimation></h2>
                            <h3 className="section-subheading text-muted wow bounceIn">   <ScrollAnimation animateIn='bounceIn' animateOnce={true}>Imagination is more important than knowledge. </ScrollAnimation></h3>
                        </div>
                    </div>
                    <div className="row">
                        {myPortfolio.map((project, i) => (
                            <div key={i} className={`col-sm-4 col-md-4 project-item wow ${project.animation}`}>
                                <ScrollAnimation animateIn={`${project.animation}`} animateOnce={true}>
                                    <OutboundLink href={project.link} className="project-link" target="_blank" rel="noopener noreferrer">
                                        <div className="project-hover">
                                            <div className="project-hover-content">
                                                <i className="fas fa-plus fa-3x"></i>
                                            </div>
                                        </div>
                                        <CustomImg image={project.projectImage} />
                                    </OutboundLink>
                                    <div className="project-caption">
                                        <h5>{project.caption}</h5>
                                        <p className="text-muted">{project.desc}</p>
                                    </div>  </ScrollAnimation>
                            </div>

                        ))}
                    </div>
                </div>
            </section>
        </div>
    );

};

export default Projects;